var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"decisionMake",attrs:{"id":"mainContent"}},[_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"01","label":"会议基本信息","data-title":"会议基本信息"}}),_c('base-form',{ref:"financingConfig",staticClass:"formData",attrs:{"componentList":_vm.financingConfig,"formAttrs":{
        model: _vm.formData,
        labelWidth: '100px',
      },"showBtns":false}})],1),_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"02","label":"会议纪要","data-title":"会议纪要"}}),_c('div',{staticClass:"top"},[_c('title-center',{attrs:{"rules":true,"name":"评审会问题及落实情况"}}),_c('div',{staticClass:"addBtn"},[_c('div',{staticClass:"addButton",on:{"click":function($event){return _vm.add('problem')}}},[_c('i',{staticClass:"el-icon-plus"})])])],1),_c('base-table',{ref:"tableData",staticClass:"order-table",attrs:{"columns":_vm.problemConfig,"showPage":false,"tableAttrs":{
        data: _vm.formData.summaryDetailVOS,
        stripe: true,
      },"is-caculate-height":false,"webPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}},{key:"description",fn:function(scope){return [_c('sz-input',{attrs:{"placeholder":"请输入","maxlength":"500","type":"textarea","rows":2,"show-word-limit":""},model:{value:(scope.row.description),callback:function ($$v) {_vm.$set(scope.row, "description", $$v)},expression:"scope.row.description"}})]}},{key:"situation",fn:function(scope){return [_c('sz-input',{attrs:{"placeholder":"请输入","maxlength":"1000","type":"textarea","rows":2,"show-word-limit":""},model:{value:(scope.row.situation),callback:function ($$v) {_vm.$set(scope.row, "situation", $$v)},expression:"scope.row.situation"}})]}},{key:"action",fn:function(scope){return [_c('icon-button',{attrs:{"content":"删除","icon":"iconfont iconshanchu1"},on:{"click":function($event){$event.preventDefault();return _vm.del('problem', scope.$index)}}})]}}])}),_c('div',{staticClass:"top"},[_c('title-center',{attrs:{"name":"风控经理信息补充"}}),_c('div',{staticClass:"addBtn"},[_c('div',{staticClass:"addButton",on:{"click":function($event){return _vm.add('measures')}}},[_c('i',{staticClass:"el-icon-plus"})])])],1),_c('base-table',{ref:"tableData",staticClass:"order-table",attrs:{"columns":_vm.measuresConfig,"showPage":false,"tableAttrs":{
        data: _vm.formData.meetingReplenishDTOS,
        stripe: true,
      },"is-caculate-height":false,"webPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}},{key:"content",fn:function(scope){return [_c('sz-input',{attrs:{"placeholder":"请输入","maxlength":"1000"},model:{value:(scope.row.content),callback:function ($$v) {_vm.$set(scope.row, "content", $$v)},expression:"scope.row.content"}})]}},{key:"action",fn:function(scope){return [_c('icon-button',{attrs:{"content":"删除","icon":"iconfont iconshanchu1"},on:{"click":function($event){return _vm.del('measures', scope.$index)}}})]}}])})],1),_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"03","label":"本次申请授信方案","data-title":"本次申请授信方案"}}),_c('base-form',{ref:"programmeConfig",staticClass:"formData",attrs:{"componentList":_vm.programmeConfig,"formAttrs":{
        model: _vm.formData,
        labelWidth: '100px',
      },"showBtns":false}})],1),_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"04","label":"文件生成与下载","data-title":"文件生成与下载"}}),_c('base-form',{ref:"fileConfig",staticClass:"formData",attrs:{"componentList":_vm.fileConfig,"formAttrs":{
        model: _vm.formData.fileListInfo,
        labelWidth: '100px',
      },"showBtns":false}})],1),_c('div',{staticClass:"bottom"},[(_vm.isExamineData)?_c('base-button',{attrs:{"label":"确定","type":"primary"},on:{"click":function($event){return _vm.send('determine')}}}):_vm._e(),(!_vm.isExamineData)?_c('base-button',{attrs:{"label":"保存","type":"primary"},on:{"click":function($event){return _vm.send('save')}}}):_vm._e(),(!_vm.isExamineData)?_c('base-button',{attrs:{"label":"提交","type":"primary"},on:{"click":function($event){return _vm.send('submit')}}}):_vm._e(),_c('base-button',{attrs:{"label":"关闭","type":"default"},on:{"click":_vm.close}})],1),_c('scroll-fool',{ref:"scrollFool"}),_c('pre-view',{attrs:{"fileId":_vm.fileId,"isOpen":true,"fileType":_vm.fileType,"count":_vm.fileIdCount}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- 评审会批复补充材料制作 -->
  <div class="decisionMake" id="mainContent">
    <!-- 融资申请信息 -->
    <div class="box">
      <text-title
        index="01"
        label="会议基本信息"
        data-title="会议基本信息"
        class="modelBox"
      ></text-title>
      <base-form
        :componentList="financingConfig"
        :formAttrs="{
          model: formData,
          labelWidth: '100px',
        }"
        ref="financingConfig"
        :showBtns="false"
        class="formData"
      />
    </div>
    <!-- 会议纪要 -->
    <div class="box">
      <text-title
        index="02"
        label="会议纪要"
        data-title="会议纪要"
        class="modelBox"
      ></text-title>
      <div class="top">
         <title-center :rules="true" name="评审会问题及落实情况" />

        <div class="addBtn">
          <div class="addButton" @click="add('problem')">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <base-table
        :columns="problemConfig"
        :showPage="false"
        :tableAttrs="{
          data: formData.summaryDetailVOS,
          stripe: true,
        }"
        :is-caculate-height="false"
        ref="tableData"
        :webPage="false"
        class="order-table"
      >
        <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <template slot="description" slot-scope="scope">
          <sz-input
            placeholder="请输入"
            maxlength="500"
            v-model="scope.row.description"
            type="textarea"
            :rows="2"
            show-word-limit
          ></sz-input>
        </template>
        <template slot="situation" slot-scope="scope">
          <sz-input
            placeholder="请输入"
            maxlength="1000"
            v-model="scope.row.situation"
             type="textarea"
            :rows="2"
            show-word-limit
          ></sz-input>
        </template>
        <template slot="action" slot-scope="scope">
          <icon-button
            content="删除"
            icon="iconfont iconshanchu1"
            @click.prevent="del('problem', scope.$index)"
          />
        </template>
      </base-table>
      <div class="top">
        <title-center  name="风控经理信息补充" />

        <div class="addBtn">
          <div class="addButton" @click="add('measures')">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <base-table
        :columns="measuresConfig"
        :showPage="false"
        :tableAttrs="{
          data: formData.meetingReplenishDTOS,
          stripe: true,
        }"
        :is-caculate-height="false"
        ref="tableData"
        :webPage="false"
        class="order-table"
      >
        <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <template slot="content" slot-scope="scope">
          <sz-input
            placeholder="请输入"
            maxlength="1000"
            v-model="scope.row.content"
          ></sz-input>
        </template>
        <template slot="action" slot-scope="scope">
          <icon-button
            content="删除"
            icon="iconfont iconshanchu1"
            @click="del('measures', scope.$index)"
          />
        </template>
      </base-table>
    </div>
    <!-- 本地申请授信方案 -->
    <div class="box">
      <text-title
        index="03"
        label="本次申请授信方案"
        data-title="本次申请授信方案"
        class="modelBox"
      ></text-title>
      <base-form
        :componentList="programmeConfig"
        :formAttrs="{
          model: formData,
          labelWidth: '100px',
        }"
        ref="programmeConfig"
        :showBtns="false"
        class="formData"
      />
    </div>
    <!-- 文件生成与下载 -->
    <div class="box">
      <text-title
        index="04"
        label="文件生成与下载"
        data-title="文件生成与下载"
        class="modelBox"
      ></text-title>
      <base-form
        :componentList="fileConfig"
        :formAttrs="{
          model: formData.fileListInfo,
          labelWidth: '100px',
        }"
        ref="fileConfig"
        :showBtns="false"
        class="formData"
      />
    </div>
    <!-- 保存提交关闭 -->
    <div class="bottom">
       <base-button label="确定" type="primary" @click="send('determine')" v-if="isExamineData"></base-button>
      <base-button label="保存" type="primary" @click="send('save')" v-if="!isExamineData"></base-button>
      <base-button label="提交" type="primary" @click="send('submit')" v-if="!isExamineData"></base-button>
      <base-button label="关闭" type="default" @click="close"></base-button>
    </div>
    <!-- 楼层 -->
    <scroll-fool ref="scrollFool" />
     <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="fileIdCount" />
  </div>
</template>

<script>
import PreView from '@/components/pre-view/pre-view.vue'
import Storage from '@/utils/storage'
import TitleCenter from '@/pages/business/components/title-center.vue'
import { reviewMeetingApi } from '@/api/businessApi'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import {
  financingConfig,
  programmeConfig,
  fileConfig,
  problemConfig,
  measuresConfig
} from '../utils/config'
import BaseForm from '@/components/common/base-form/base-form.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
import SzInput from '@/components/input/sz-input/sz-input.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { getDictLists } from '@/filters/fromDict'
export default {
  components: {
    TextTitle,
    BaseForm,
    BaseTable,
    IconButton,
    SzInput,
    BaseButton,
    ScrollFool,
    TitleCenter,
    PreView
  },
  props: {
    gysData: Array,
    providertData: Array
  },
  data () {
    return {
      financeInterestRateType: false,
      fileType: '',
      fileId: '',
      fileIdCount: 0,
      isExamineData: false,
      formData: { fileListInfo: { fileId: '' } },
      textarea: '',
      fileData: { },
      generateFileId: '',
      financingData: {},
      programmeData: { validTimeUnit: '' },
      problemData: [],
      measuresData: []
    }
  },
  // 计算属性 类似于data概念
  computed: {
    api () {
      return reviewMeetingApi
    },
    problemConfig () {
      return problemConfig(this)
    },
    measuresConfig () {
      return measuresConfig(this)
    },
    fileConfig () {
      return fileConfig(this)
    },
    financingConfig () {
      return financingConfig(this)
    },
    programmeConfig () {
      return programmeConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 选择资金方修改融资利率
    changeCapitalSideId (data) {
      let obj = {}
      this.providertData.map((item) => {
        if (item.keyId === data) {
          obj = item
        }
      })
      console.log(this.formData, 'formData')
      console.log(obj)
      this.formData.capitalSideName = obj.capitalSideName
      this.formData.capitalSideProductId = obj.capitalSideProductId
      this.formData.capitalSideProductName = obj.productName

      if (obj.capitalSideName === '建设银行') {
        this.$set(this.formData, 'financeInterestRate', 4.95)
        this.financeInterestRateType = true
      } else if (obj.capitalSideName === '深国际' || obj.capitalSideName === '华润银行' || obj.capitalSideName === '测试银行zml' || obj.capitalSideName === '财富资金') {
        this.$set(this.formData, 'financeInterestRate', '')
        this.financeInterestRateType = false
      } else if (obj.capitalSideName === '中小企') {
        this.$set(this.formData, 'financeInterestRate', 8)
        this.financeInterestRateType = true
      }
    },
    // 删除文件
    delFile () {
      this.$set(this.formData.fileListInfo, 'fileId', '')
      this.$set(this.formData.fileListInfo, 'fileName', '')
    },
    previewShow () {
      this.fileId = this.formData.fileListInfo.fileId
      const fileType = this.formData.fileListInfo.fileName.split('.')
      this.fileType = fileType[fileType.length - 1]
      this.fileIdCount++
    },
    // 数据校验
    dataVerification () {
    // 数据校验
      let pass = true
      let errorMsg = ''
      // 基本会议信息校验
      this.$refs.financingConfig.validate((valid) => {
        if (!valid) {
          pass = false
          errorMsg = '请完善会议基本信息'
          this.warning(errorMsg)
          this.$refs.scrollFool.touchactive(0)
          return false
        }
      })
      if (!pass) {
        return false
      }
      if (parseFloat((this.formData.financeRate * 1000 / 10 - this.formData.serviceRate * 1000 / 10 - this.formData.financeInterestRate * 1000 / 10).toFixed(2)) !== 0) {
        pass = false
        errorMsg = '综合融资利率需等于服务费率与融资利息率之和'
        this.warning(errorMsg)
        this.$refs.scrollFool.touchactive(2)
      }
      if (!pass) {
        return false
      }
      // 评审会问题及落实情况数据校验
      if (this.formData.summaryDetailVOS.length === 0) {
        pass = false
        this.warning('请添加评审会问题及落实情况')
        this.$refs.scrollFool.touchactive(1)
        return false
      } else {
        this.formData.summaryDetailVOS.forEach((item) => {
          if (!item.description || !item.situation) {
            pass = false
          }
        })
        if (!pass) {
          this.warning('请完善评审会问题及落实情况')
          this.$refs.scrollFool.touchactive(1)
          return false
        }
      }
      if (!pass) {
        return false
      }
      // 本次申请授信方案数据校验
      this.$refs.programmeConfig.validate((valid) => {
        if (!valid) {
          pass = false
          errorMsg = '请完善本次申请授信方案'
          this.$refs.scrollFool.touchactive(2)
          this.warning(errorMsg)
          return false
        }
      })

      return pass
    },
    // 文件生成
    generate (item, type) {
      const data = {
        businessId: item.businessId,
        type: 'PSH_LS'
      }
      this.api.getGenerateDo(data).then(res => {
        if (res.data) {
          this.$set(this.formData.fileListInfo, 'fileId', res.data.keyId)
          this.$set(this.formData.fileListInfo, 'fileName', res.data.fileName)
          if (!type) {
            this.success('文件生成成功')
          } else {
            this.$router.back()
          }
        }
      })
    },
    // 数据保存
    async   save (data, type) {
      return await this.api.save(data).then(res => {
        if (type !== 'generate') {
          this.success('保存成功')
        }
        return res.data
      })
    },
    // 数据验证
    async send (type) {
      const data = this.formData
      console.log(data, type, 'data')
      if (data.repayTypeCode) {
        const [obj] = getDictLists('REPAY_TYPE_STATUS').filter(item => item.dictId === data.repayTypeCode.toString())
        data.repayTypeName = obj.dictName
      }
      data.meetingReviewType = 'SUPPLEMENTARY'
      data.meetingPlanInfoDTO = {
        businessId: data.businessId,
        capitalSideId: data.capitalSideId,
        expectedQuota: data.stockWsfBalance,
        financeAmount: data.stockWxfBalanceInThisCase,
        financeRate: data.financeRate,
        financeTerm: data.financeTerm,
        financeTermUnit: data.financeTermUnit,
        keyId: data.planKeyId,
        repayTypeCode: data.repayTypeCode,
        repayTypeName: data.repayTypeName,
        stockWbfBalance: data.stockWbfBalance,
        stockWxfBalance: data.stockWxfBalance,
        totalPledgeRate: data.pledgeRate,
        financeInterestRate: data.financeInterestRate,
        serviceRate: data.serviceRate,
        capitalSideName: data.capitalSideName,
        capitalSideProductId: data.capitalSideProductId,
        capitalSideProductName: data.capitalSideProductName
      }
      data.summaryDetailDTOS = data.summaryDetailVOS
      if (type === 'save') {
        this.save(data)
      } else {
        const pass = this.dataVerification()
        if (!pass) {
          return false
        }
        if (type === 'submit') {
          let newPass = true
          this.$refs.fileConfig.validate((valid) => {
            if (!valid) {
              newPass = false

              this.$refs.scrollFool.touchactive(3)
              this.warning('请生成或上传补充材料文件')
              return false
            }
          })
          if (newPass) {
            this.submit(data)
          }
        } else if (type === 'determine') {
          const res = await this.save(data, type)
          console.log(res, 'res')
          if (res) {
            this.generate(data, type)
          }
        } else {
          const res = await this.save(data, type)
          console.log(res, 'res')
          if (res) {
            this.generate(data)
          }
        }
      }
    },
    // 数据提交
    submit (data) {
      console.log(111)
      this.api.submit(data).then(res => {
        if (res.data) {
          this.success('提交成功')
          this.$router.back()
        }
      })
    },
    // 自动计算授信总额
    changeGivingCreditAmountTotal () {
      const data = this.formData
      const amount = data.stockWxfBalance * 1 + data.stockWsfBalance * 1 + data.stockWbfBalance * 1 + data.stockWxfBalanceInThisCase * 1
      console.log(data.stockWxfBalance * 1, data.stockWsfBalance * 1, data.stockWbfBalance * 1, data.stockWxfBalanceInThisCase * 1, amount)
      this.$set(this.formData, 'givingCreditAmountTotal', amount.toFixed(2))
    },
    getDetail () {
      const params = {
        businessNo: this.$route.query.businessNo,
        meetingType: 'SUPPLEMENTARY'
      }
      this.api.detail(params).then((res) => {
        this.formData = res.data

        if (this.formData && this.formData.capitalSideId) {
          if (this.formData.capitalSideId === '2111091147472850001' || this.formData.capitalSideId === '2112280944412630001') {
            this.financeInterestRateType = true
          }
        }
        if (!this.formData.fileListInfo) {
          this.formData.fileListInfo = {}
        }
        this.$nextTick(() => {
          this.$refs.programmeConfig.clearValidate()
          this.$refs.fileConfig.clearValidate()
        })
      })
    },
    close () {
      this.$router.back()
    },
    // 删除
    del (type, index) {
      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (type === 'problem') {
            this.formData.summaryDetailVOS.splice(index, 1)
          }
          if (type === 'measures') {
            this.formData.meetingReplenishDTOS.splice(index, 1)
          }
        })
        .catch(() => {})
    },
    add (type) {
      if (type === 'problem') {
        if (this.formData.summaryDetailVOS.length >= 30) {
          this.warning('评审会问题最多添加30条')
        } else {
          this.formData.summaryDetailVOS.push({})
        }
      }
      if (type === 'measures') {
        if (this.formData.meetingReplenishDTOS.length >= 30) {
          this.warning('贷后措施最多添加30条')
        } else {
          this.formData.meetingReplenishDTOS.push({})
        }
      }
    },
    changeValidTimeUnit (data) {
    // this.programmeData.validTimeUnit = data
      this.$set(this.formData, 'financeTermUnit', data)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.isExamineData = Storage.getLocal('contractInfo').isExamineData
    this.getDetail()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.bottom {
  width: 100%;
  height: 60px;
  display: flex;
  text-align: center;
  background: #4A7CF0;
  align-items: center;
  justify-content: center;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    color: #2862E7;
  }
}
// 表单样式
.formData {
  background: #f9f9f6;
  padding: 20px 11px;
  margin-bottom: 18px;
  /deep/ textarea {
    border-color: #D9E5EE;
    resize: none;
  }
  /deep/ .el-textarea {
    .el-textarea__inner {
      border-color: #D9E5EE;
    }
  }

  /deep/ .el-form-item__content {
    height: auto !important;
  }
}

// 结构样式
.decisionMake {
  padding: 0;
  height: calc(100% - 41px) !important;
  background: #f9f9f6;
  overflow-y: scroll;

  .box {
    background: #fff;
    margin-top: 10px;
  }
  .formData {
    margin: 0 !important ;
    background: #fff;
  }
}

.addBtn {
  padding-right: 19px;

  .addButton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
